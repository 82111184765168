@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* global css */
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

/* [dir="rtl"] .MuiInputLabel-root{
  transform-origin: right !important;
  left: inherit !important;
  right: 1.75rem !important;
  font-size: small;
  color: #807D7B;
  font-weight: 400;
  overflow: "unset",
} */

.progress-bar {
  width: 100%;
  background-color: #424867;
  animation: flowAnimation 2s linear infinite;
}